<template lang="">
  <div
    class="flex fixed z-10 bg-black bg-opacity-50 w-screen h-full max-sm:bg-[#f2f4f5] max-sm:bg-opacity-80 max-sm:px-6"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <div
          class="max-sm:flex fold:flex justify-between items-center mt-[12%] sm:mt-0"
        >
          <h2
            class="sm:text-[30px] sm:font-bold text-[20px] max-sm:font-regular fold:font-regular max-sm:text-[#444] fold:text-[#444] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
          >
            {{ $t('withdraw_detail.title') }}
          </h2>
          <button class="sm:hidden" @click="setModalControl">
            <img
              src="@/assets/image/icon_close.png"
              alt=""
              class="w-[30px] dark:hidden"
            />
            <img
              src="@/assets/image/icon_close_d.png"
              alt=""
              class="w-[30px] hidden dark:block"
            />
          </button>
        </div>
      </div>
      <div
        class="text-left sm:text-[20px] text-[15px] sm:mt-[40px] mt-[8%] max-sm:leading-[180%] fold:leading-[180%]"
      >
        <div class="flex">
          <div class="basis-1/3">{{ $t('common.asset') }}</div>
          <div
            class="basis-2/3 text-[16px] font-light max-sm:text-right fold:text-right"
          >
            {{ withdrawData.symbol.toUpperCase() }}
          </div>
        </div>
        <div class="flex">
          <div class="basis-1/3">{{ $t('withdraw_detail.network') }}</div>
          <div
            class="basis-2/3 text-[16px] font-light max-sm:text-right fold:text-right"
          >
            {{
              withdrawData.net == 'eth' || withdrawData.net == 'usdt-eth'
                ? 'ERC20'
                : withdrawData.net == 'usdt-tron'
                ? 'TRC20'
                : withdrawData.net.toUpperCase()
            }}
          </div>
        </div>
        <div class="flex">
          <div class="basis-1/3">{{ $t('mywallet.withdraw.word.amount') }}</div>
          <div
            class="basis-2/3 text-[16px] font-light max-sm:text-right fold:text-right"
          >
            {{ withdrawData.amount }}
          </div>
        </div>
        <div class="flex fold:justify-between max-sm:justify-between">
          <div class="sm:basis-1/3">{{ $t('withdraw_detail.commission') }}</div>
          <div
            class="sm:basis-2/3 text-[16px] font-light max-sm:text-right fold:text-right"
          >
            <!-- {{
              withdrawData.symbol == 'btc'
                ? netFee.btc
                : withdrawData.symbol == 'eth'
                ? netFee.eth
                : withdrawData.symbol == 'xrp'
                ? netFee.xrp
                : withdrawData.symbol == 'usdt'
                ? netFee.usdt
                : ''
            }} -->
            {{ netFee[withdrawData.symbol] }}
            {{ withdrawData.symbol.toUpperCase() }}
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="sm:basis-1/3">
            {{ $t('withdraw_detail.getQty') }}
          </div>
          <div
            class="sm:basis-2/3 text-[16px] sm:text-left text-right font-light break-words"
          >
            {{ withdrawData.amount - netFee[withdrawData.symbol] }}
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="sm:basis-1/3">
            {{ $t('mywallet.withdraw.word.withdrawaddress') }}
          </div>
          <div
            class="sm:basis-2/3 text-[16px] sm:text-left text-right font-light break-words"
          >
            {{ withdrawData.address }}
          </div>
        </div>
      </div>
      <div
        class="w-full h-[160px] sm:mt-[30px] sm:border border-[#c9c9c9] dark:border-[#606060] sm:rounded overflow-y-scroll max-sm:bg-[#f7f8fa] fold:bg-[#f7f8fa] max-sm:dark:bg-[#3b3b43] fold:dark:bg-[#3b3b43] my-[10%] sm:mb-0"
      >
        <div
          class="h-[300px] p-4 text-left text-[14px] font-light leading-[18px]"
        >
          <h2 class="text-[16px] font-medium">
            {{ $t('mywallet.withdraw.word.precautions') }}
          </h2>
          <p class="mt-[10px]">
            {{ $t('mywallet.withdraw.msg.withdraw_msg1') }}
            <br />{{ $t('mywallet.withdraw.msg.withdraw_msg2') }}
          </p>
          <p>
            {{ $t('mywallet.withdraw.msg.withdraw_msg3') }}<br />{{
              $t('mywallet.withdraw.msg.withdraw_msg4')
            }}<br />{{ $t('mywallet.withdraw.msg.withdraw_msg5') }}
          </p>
          <p class="">
            {{ $t('mywallet.withdraw.msg.withdraw_msg6') }}<br />{{
              $t('mywallet.withdraw.msg.withdraw_msg7')
            }}
          </p>
        </div>
      </div>

      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel" @click="setModalControl">
          {{ $t('common.cancel') }}
        </button>
        <button :class="tStyle.confirm" @click="withdrawSubmit">
          {{ $t('withdraw_detail.execution') }}
        </button>
      </div>

      <button :class="tStyle.close_bt">
        <img
          src="../../assets/image/group2322.png"
          alt=""
          @click="setModalControl"
        />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, apiLanguage } from '@/store/store';
import { withdrawDetail, withdrawAuthType } from '@/store/walletStore';
import create from 'vue-zustand';
import Request from '@/utils/Request';
import AesEncrypt from '../Security/AesEncrypt';

export default {
  name: 'WithdrawDetail',
  data() {
    return {
      buttonAction: false,
      netFee: {
        btc: '0.0005',
        eth: '0.01',
        xrp: '1',
        usdt: '6'
      },
      tStyle: {
        inner:
          'bg-white w-full sm:w-[600px] sm:h-[640px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] relative text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] px-6 max-sm:flex fold:flex flex-col',
        title: 'sm:mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        fail: 'text-center text-[#e7224b] text-[22px]',

        bt_box:
          'sm:mt-[48px] text-center sm:text-[22px] text-[14px] dark:text-[#333] max-sm:flex fold:flex mt-auto pb-6 sm:pb-0',
        cancel:
          'sm:w-[249px] basis-1/2 sm:h-[60px] py-1 sm:py-0 border border-[#cfe4fe] rounded-[3px] dark:bg-[#fff8e8] dark:border-[#fff8e8]',
        confirm:
          'sm:w-[249px] basis-1/2 sm:h-[60px] py-1 sm:py-0 bg-[#cfe4fe] sm:ml-[20px] ml-2 rounded-[3px] dark:bg-[#ffb500]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setModalName, setAlertData, setAlertOpen } =
      useModalControl();
    const useDetail = create(withdrawDetail);
    const { withdrawData } = useDetail();
    const useAuthType = create(withdrawAuthType);
    const { setAuthType, setAuthUid, pwChangeDataSet } = useAuthType();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalControl,
      withdrawData,
      setModalName,
      setAuthType,
      setAuthUid,
      apiLangCode,
      pwChangeDataSet,
      setAlertData,
      setAlertOpen
    };
  },
  methods: {
    async withdrawSubmit() {
      if (!this.buttonAction) {
        this.buttonAction = true;
        const data = {
          symbol: this.withdrawData.symbol,
          net: this.withdrawData.net,
          percent_yn: 'N',
          key_main: this.withdrawData.address,
          quantity: this.withdrawData.amount,
          key_sub: this.withdrawData.keysub
        };

        const reqData = JSON.stringify({
          data: AesEncrypt(data)
        });
        let result = await Request(
          '/api/exs/user/v1/request_user_output',
          'post',
          this.apiLangCode[this.$i18n.locale],
          reqData
        );

        if (!result) {
          return;
        }
        this.buttonAction = false;
        let { API_CODE, authType, uid } = result;

        if (API_CODE.status === 'L402') {
          this.setAlertData({
            title: '',
            desc: this.$t('login.msg15')
          });
          this.setAlertOpen();
          localStorage.removeItem('fubit_token');
          localStorage.removeItem('fubit_rtoken');
          this.goMainPage();
          this.setLogout();
          return;
        } else if (API_CODE.status === '0000') {
          this.setAuthUid(uid);
          this.setAuthType(authType);
          this.pwChangeDataSet(null);
          this.setModalName('SnsVerification');
        } else {
          this.setAlertData({
            title: API_CODE.title,
            desc: API_CODE.message
          });
          this.setAlertOpen();
        }
      }
    }
  },
  mounted() {}
};
</script>
<style lang=""></style>
